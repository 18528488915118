import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Title from '../atoms/typography/Title';
import Alert from '../atoms/typography/Alert';
import Checkbox from '../atoms/Checkbox';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';

// #### Yup validation schema

const schema = yup.object().shape({
    email: yup
        .string()
        .email('Email is invalid')
        .required('Email is required *'),
    policy: yup
        .boolean()
        .oneOf([true], 'You must agree to the privacy policy *'),
});

type FormProps = {
    className?: string;
};

const Form: React.FC<FormProps> = ({ className }) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: {
            errors: { email, policy },
        },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const [isSubmitSuccessful, setisSubmitSuccessful] = useState(false);
    const customId = 'custom-id-yes'; // we need to assign a custom id in order to prevent toaster duplication

    // ##### Post request
    const url = 'https://wink.by:49160/api/user';

    const sendEmail = async (url: string, data: any) => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: data.email }),
        };
        try {
            const fetchResponse = await fetch(url, settings);

            if (fetchResponse.status === 409) {
                console.log(fetchResponse);
                toast.warn('Email is already in use!', {
                    toastId: customId,
                });
                return;
            }

            const data = await fetchResponse.json();
            toast.success('Thanks! Your email has been registered', {
                autoClose: 3000,
            });
            setisSubmitSuccessful(true);
            console.log(data);
        } catch (e) {
            toast.error('Something went wrong');
            return e;
        }
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({ email: '', policy: false });
        }
        setisSubmitSuccessful(false);
    }, [reset, isSubmitSuccessful]);

    return (
        <div
            className={`bg-zinc-100 shadow-xl rounded-3xl w-[350px] sm:w-[500px] sm:h-[360px] p-8 ${className}`}>
            <Title
                text="Let's keep in touch."
                className="mt-4 mb-8 text-[#182628]"
            />
            <form
                onSubmit={handleSubmit(data => {
                    sendEmail(url, data);
                })}>
                <Input
                    register={register}
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    className={`mb-2 mt-4 ${email ? 'border-red-500' : ''}`}
                />
                {email && <Alert text={`${email?.message}`} />}
                <Checkbox
                    register={register}
                    id="policy"
                    text="I agree to the "
                    children={
                        <a
                            href="/policy.html"
                            target="_blank"
                            className="underline">
                            Privacy Policy
                        </a>
                    }
                />
                {policy && <Alert text={`${policy?.message}`} />}
                <Button
                    text="Join CryptoPay Newsletter"
                    name="joinButton"
                    className="transform hover:scale-small"
                />
                <ToastContainer />
            </form>
        </div>
    );
};

export default Form;
