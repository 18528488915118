type ButtonProps = {
    type?: 'button' | 'submit' | 'reset' | undefined;
    text: string;
    name: string;
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
};

const Button: React.FC<ButtonProps> = props => {
    const { text, className, ...otherProps } = props;
    return (
        <button
            {...otherProps}
            className={`bg-[#182628] p-3 mt-6 text-white rounded-full w-full font-lato tracking-wider ${className}`}>
            {text}
        </button>
    );
};

export default Button;
