import Perk from '../atoms/Perk';
import Ethereum from '../../assets/icons/ethereum.png';
import Safe from '../../assets/icons/safe.png';
import List from '../../assets/icons/list.png';
import { useState, useEffect } from 'react';

type PerksProps = {
    className?: string;
};

const Perks: React.FC<PerksProps> = ({ className }) => {
    const [isDesktop, setDesktop] = useState(window.innerWidth > 640);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 640);
    };

    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });

    return (
        <div
            className={`scale-[0.70] sm:scale-90 flex flex-row mx-auto items-center justify-center sm:justify-start rounded-3xl bg-[#182628] p-4 mt-4`}>
            <Perk
                title="SAFE"
                description={
                    isDesktop
                        ? `We guarantee secure payments and data protection.`
                        : ''
                }
                image={Safe}
                imageClasses="ml-3 sm:ml-0"
            />
            <Perk
                title="INTUITIVE"
                description={
                    isDesktop
                        ? 'View your transactions every time you want.'
                        : ''
                }
                image={List}
                textClasses="ml-2 sm:ml-0"
                imageClasses="ml-14 sm:ml-0"
            />
            <Perk
                title="FLEXIBLE"
                description={
                    isDesktop ? 'Large amount of crypto supported.' : ''
                }
                image={Ethereum}
                imageClasses="ml-12 sm:ml-0"
            />
        </div>
    );
};

export default Perks;
