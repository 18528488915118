type InputProps = {
    register: (type: string) => void;
    type: string;
    name: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
};

const Input: React.FC<InputProps> = ({
    register,
    type,
    name,
    placeholder,
    className,
    disabled,
}) => {
    return (
        <input
            {...register('email')}
            type={type}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            className={`border-2 border-zinc-100 rounded-full w-full text-gray-700 p-2.5 focus:outline-primary-green ${className}`}
        />
    );
};

export default Input;
