type AlertProps = {
    text: string;
    className?: string;
};

const Alert: React.FC<AlertProps> = ({ text, className }) => {
    return (
        <p className={`text-red-500 ml-1 text-s italic ${className}`}>{text}</p>
    );
};

export default Alert;
