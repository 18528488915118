type CheckboxProps = {
    register: (field: string) => void;
    text: string;
    id: string;
    children?: JSX.Element;
    className?: string;
};

const Checkbox: React.FC<CheckboxProps> = ({
    text,
    children,
    id,
    register,
    className,
}) => {
    return (
        <div className="focus:outline-none mt-1 ml-1">
            <input
                {...register('policy')}
                className="h-4 w-4 mt-1 align-top mr-2 cursor-pointer"
                type="checkbox"
                id={id}
                name={id}
            />
            <label
                className={`inline-block text-[#182628] ${className}`}
                htmlFor={id}>
                {text}
                {children}
            </label>
        </div>
    );
};

export default Checkbox;
