import Form from '../molecules/Form';
import Header from '../molecules/Header';
import Perks from '../molecules/Perks';
import Footer from '../molecules/Footer';
import { VIDEO_URL } from '../../costants/costants';

const Landing = () => {
    return (
        <div className="flex flex-col justify-between bg-curves bg-cover min-h-screen bg-top bg-[top-5]">
            <Header
                text="Leave us your "
                children={
                    <span className="text-green-600">
                        email <span className="text-[#182628]"> to </span> stay
                        updated.
                    </span>
                }
                // TODO ADD VIDEO URL
                url={VIDEO_URL}
            />
            <div className="justify-center flex flex-row sm:justify-end sm:mr-20 mt-18">
                <Form />
            </div>
            <div>
                <div className="flex justify-center sm:justify-start sm:max-w-[1000px]">
                    <Perks />
                </div>
                <div className="mt-4 pb-6">
                    <Footer text="powered by " childText="WiNK"></Footer>
                </div>
            </div>
        </div>
    );
};

export default Landing;
