type TitleProps = {
    text: string;
    children?: JSX.Element;
    color?: string;
    className?: string;
};

const Title: React.FC<TitleProps> = ({ text, color, children, className }) => {
    return (
        <p
            className={`${color} text-2xl sm:text-3xl font-lato font-bold text-center ${className}`}>
            {text}
            {children}
        </p>
    );
};

export default Title;
