import SubTitle from './typography/SubTitle';

type LinkProps = {
    text: string;
    url: string;
    image: string;
    children?: JSX.Element;
    className?: string;
};

const Link: React.FC<LinkProps> = ({ text, className, image, url }) => {
    return (
        <div className="justify-center mb-10 sm:mb-0 sm:ml-12 mt-8 flex flex-row sm:justify-start items-center text-[#182628]">
            <div>
                <img src={image} className="mr-4 h-8" alt="Logo" />
            </div>
            <div>
                <a href={url} target="_blank">
                    <SubTitle
                        text={text}
                        className={`underline ${className}`}
                    />
                </a>
            </div>
        </div>
    );
};

export default Link;
