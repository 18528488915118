type SubTitleProps = {
    text: string;
    color?: string;
    className?: string;
    children?: JSX.Element;
};

const SubTitle: React.FC<SubTitleProps> = ({
    text,
    color,
    className,
    children,
}) => {
    return (
        <p
            className={`${color} text-xl font-lato font-bold tracking-wider text-center ${className}`}>
            {text}
            {children}
        </p>
    );
};

export default SubTitle;
