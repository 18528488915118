import SubTitle from '../atoms/typography/SubTitle';

type FooterProps = {
    text: string;
    childText?: string;
    className?: string;
};

const Footer: React.FC<FooterProps> = ({ className, text, childText }) => {
    return (
        <div>
            <SubTitle
                text={text}
                children={
                    <a
                        href="https://wink.by"
                        target="_blank"
                        className="border-b-2">
                        {childText}
                    </a>
                }
                className={`italic text-white tracking-footer ${className}`}
            />
        </div>
    );
};

export default Footer;
