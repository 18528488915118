import SubTitle from './typography/SubTitle';

type PerkProps = {
    title: string;
    description: string;
    image: string;
    imageClasses?: string;
    textClasses?: string;
};

const Perk: React.FC<PerkProps> = ({
    title,
    description,
    image,
    imageClasses,
    textClasses,
}) => {
    return (
        <div className="scale-[0.9] ml-4 mb-2 flex flex-col items-start text-white">
            <SubTitle
                text={title}
                className={`ml-1 mb-1 tracking-widest ${textClasses}`}
            />
            <div className="flex flex-row sm:items-center">
                <img
                    src={image}
                    alt="logo"
                    className={`w-16 mr-4 ${imageClasses}`}
                />
                <p className={'italic max-w-[70%] sm:block'}>{description}</p>
            </div>
        </div>
    );
};

export default Perk;
