import Title from '../atoms/typography/Title';
import Link from '../atoms/Link';
import Logo from '../../assets/cryptopay_logo.svg';
import Youtube from '../../assets/icons/youtube_logo.svg';

type HeaderProps = {
    text: string;
    url: string;
    children?: JSX.Element;
    className?: string;
};

const Header: React.FC<HeaderProps> = ({ text, children, className, url }) => {
    return (
        <div className={`flex flex-col justify-start ${className}`}>
            <div>
                <img
                    src={Logo}
                    className="mx-auto mt-2 sm:mt-8 sm:mb-2 sm:ml-0 h-24"
                    alt="Logo"
                />
            </div>
            <div>
                <Title
                    text={text}
                    children={children}
                    className="block mx-auto sm:inline-block sm:ml-12 tracking-small md:tracking-header text-[#182628]"
                />
            </div>
            <div>
                <Link
                    image={Youtube}
                    url={url}
                    text="Discover More"
                    className="transform hover:scale-small" />
            </div>
        </div>
    );
};

export default Header;
